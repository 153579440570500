/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type MetaProps = JSX.IntrinsicElements['meta'];

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: MetaProps[];
  title: string;
};

const Seo: React.FC<SEOProps> = ({ title, description, lang, meta = [] }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
            keywords
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultMeta: MetaProps[] = [
    {
      name: 'description',
      content: metaDescription
    },
    {
      name: 'keywords',
      content: site.siteMetadata.keywords.join(',')
    },
    {
      property: 'og:title',
      content: `${site.siteMetadata.title} | ${title}`
    },
    {
      property: 'og:description',
      content: metaDescription
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      name: 'twitter:card',
      content: 'summary'
    },
    {
      name: 'twitter:creator',
      content: site.siteMetadata.author
    },
    {
      name: 'twitter:title',
      content: `${site.siteMetadata.title} | ${title}`
    },
    {
      name: 'twitter:description',
      content: metaDescription
    }
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[...defaultMeta, ...meta]}
    />
  );
};

Seo.defaultProps = {
  lang: 'en',
  description: ''
};

export default Seo;
